var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('modal-confirm-generic',{ref:"unSaveChangesModal",attrs:{"description":_vm.$t('components/lims/modals/ModalFormCancel.text')},on:{"onConfirm":_vm.onUnSaveChanges}}),_c('form',{staticClass:"md-layout lims-form my-account-setting",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('lims-block',{staticClass:"information-block"},[_c('h4',{staticClass:"title",attrs:{"slot":"blockTitle"},slot:"blockTitle"},[_vm._v(_vm._s(_vm.$t('blocks.tittle.settings')))]),_c('modal-scan-qr-code',{ref:"scanQrCodeModal",attrs:{"slot":"blockTitle","qr-code-uri":_vm.formData.qrCodeUri},on:{"onNext":_vm.onCompleteScanQrCode,"onShowSecretCode":_vm.onShowSecretCode},slot:"blockTitle"}),_c('modal-show-secret-code',{ref:"showSecretCode",attrs:{"slot":"blockTitle","secret-code":_vm.formData.secretCode},on:{"onNext":_vm.onCompleteScanQrCode},slot:"blockTitle"}),_c('modal-verify-code',{ref:"verifyCodeModal",attrs:{"slot":"blockTitle","option":_vm.verifyContent,"is-wrong-code":_vm.isWrongCode},on:{"onVerify":_vm.onVerifyCode},slot:"blockTitle"}),_c('modal-verify-successfully',{ref:"verifySuccessfullyModal",attrs:{"slot":"blockTitle","method":_vm.verifyContent,"authySuccess":true},on:{"onVerifySuccessfully":_vm.onVerifySuccessfully},slot:"blockTitle"}),_c('div',{attrs:{"slot":"blockContent"},slot:"blockContent"},[_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.myAccountSettingSchema,"field":"preferredTwoFaMethod","is-preferred-two-fa-method":_vm.preferredTwoFaMethodSelected}},[_c('v-select',{attrs:{"slot":"field","options":_vm.preferredTwoFaMethodList},slot:"field",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}}],null,true),model:{value:(_vm.formData.preferredTwoFaMethod),callback:function ($$v) {_vm.$set(_vm.formData, "preferredTwoFaMethod", $$v)},expression:"formData.preferredTwoFaMethod"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-60 md-small-size-100"},[(_vm.hadSetupAuthy)?_c('div',[_vm._v(_vm._s(_vm.$t('page/myAccount/setting/setUp2FATxt')))]):_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('entities/my-account/settings/setUpAuthy')))])]),_c('md-button',{staticClass:"setUp2FABtn",attrs:{"slot":"field"},on:{"click":_vm.onClickSetupTwoFaViaAuthy},slot:"field"},[_vm._v(_vm._s(_vm.$t('page/myAccount/setting/setUp2FABtn')))])],1)]),_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.myAccountSettingSchema,"field":"defaultNumberOfRecordsOnList"}},[_c('v-select',{attrs:{"slot":"field","options":_vm.itemPerPageList},slot:"field",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}}],null,true),model:{value:(_vm.formData.defaultNumberOfRecordsOnList),callback:function ($$v) {_vm.$set(_vm.formData, "defaultNumberOfRecordsOnList", $$v)},expression:"formData.defaultNumberOfRecordsOnList"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.myAccountSettingSchema,"field":"language"}},[_c('v-select',{attrs:{"slot":"field","options":_vm.languageOptionDummy},slot:"field",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}}],null,true),model:{value:(_vm.formData.language),callback:function ($$v) {_vm.$set(_vm.formData, "language", $$v)},expression:"formData.language"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.myAccountSettingSchema,"field":"timeZone"}},[_c('v-select',{staticClass:"wrap-long-items",attrs:{"slot":"field","options":_vm.timeZoneList},slot:"field",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(label)+" ")]}}],null,true),model:{value:(_vm.formData.timeZone),callback:function ($$v) {_vm.$set(_vm.formData, "timeZone", $$v)},expression:"formData.timeZone"}})],1)],1)]),_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"}),(_vm.isPathView)?_c('div',{staticClass:"md-layout-item md-size-33 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.myAccountSettingSchema,"field":"reportingStatusId"}},[_c('lims-tooltip',{attrs:{"slot":"label-info","content":_vm.$t('entities/my-account/settings/tooltip.reportingStatus')},slot:"label-info"}),_c('v-select',{staticClass:"wrap-long-items",attrs:{"slot":"field","options":_vm.reportingStatusList},slot:"field",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.reportingStatusId),callback:function ($$v) {_vm.$set(_vm.formData, "reportingStatusId", $$v)},expression:"formData.reportingStatusId"}})],1)],1):_vm._e()])])],1),_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 lims-form-actions"},[_c('LimsFormCancel',{attrs:{"redirect-url":'/my-account'}}),_c('md-button',{staticClass:"md-button md-primary lims-form-button md-theme-default",on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t('global/button/button.save'))+" ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }