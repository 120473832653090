<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <form class="md-layout lims-form my-account-setting" @submit.prevent="handleSubmit()">
      <lims-block class="information-block">
        <h4 class="title" slot="blockTitle">{{ $t('blocks.tittle.settings') }}</h4>
        <modal-scan-qr-code
          slot="blockTitle"
          ref="scanQrCodeModal"
          :qr-code-uri="formData.qrCodeUri"
          @onNext="onCompleteScanQrCode"
          @onShowSecretCode="onShowSecretCode"
        ></modal-scan-qr-code>
        <modal-show-secret-code
          slot="blockTitle"
          ref="showSecretCode"
          :secret-code="formData.secretCode"
          @onNext="onCompleteScanQrCode"
        ></modal-show-secret-code>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
        ></modal-verify-code>
        <modal-verify-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="verifyContent"
          :authySuccess="true"
          @onVerifySuccessfully="onVerifySuccessfully"
        ></modal-verify-successfully>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="myAccountSettingSchema"
                field="preferredTwoFaMethod"
                :is-preferred-two-fa-method="preferredTwoFaMethodSelected"
              >
                <v-select slot="field" :options="preferredTwoFaMethodList" v-model="formData.preferredTwoFaMethod">
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-60 md-small-size-100">
              <div v-if="hadSetupAuthy">{{ $t('page/myAccount/setting/setUp2FATxt') }}</div>
              <div v-else>
                <label>{{ $t('entities/my-account/settings/setUpAuthy') }}</label>
              </div>
              <!-- <lims-field :model="formData" :schema="myAccountSettingSchema" field="setUpAuthy"> -->
              <md-button slot="field" @click="onClickSetupTwoFaViaAuthy" class="setUp2FABtn">{{
                $t('page/myAccount/setting/setUp2FABtn')
              }}</md-button>
              <!-- </lims-field> -->
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="myAccountSettingSchema" field="defaultNumberOfRecordsOnList">
                <v-select slot="field" :options="itemPerPageList" v-model="formData.defaultNumberOfRecordsOnList">
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="myAccountSettingSchema" field="language">
                <v-select slot="field" :options="languageOptionDummy" v-model="formData.language">
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="myAccountSettingSchema" field="timeZone">
                <v-select slot="field" :options="timeZoneList" v-model="formData.timeZone" class="wrap-long-items">
                  <template #option="{ label }">
                    {{ label }}
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100"></div>
            <div v-if="isPathView" class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="myAccountSettingSchema" field="reportingStatusId">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/my-account/settings/tooltip.reportingStatus')"
                ></lims-tooltip>
                <v-select
                  slot="field"
                  :options="reportingStatusList"
                  v-model="formData.reportingStatusId"
                  class="wrap-long-items"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
        </div>
      </lims-block>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirect-url="'/my-account'" />
          <md-button @click="onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, UnSaveChangesMixins, TabMixins } from '@/core/mixins';
import { DropdownService, MyAccountService } from '@/services';
import { APP_ROUTES, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { mapGetters } from 'vuex';
import { ModalScanQrCode } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalVerifySuccessfully } from '@/components/Lims/modals';
import { VERIFY_SMS_ERROR } from '@/core/error-constants';
import ModalShowSecretCode from '@/components/Lims/modals/ModalShowSecretCode';
import { getMyAccountSettingsSchema } from '@/schemas/my-account-settings.schema';

export default {
  name: 'dropdown-item-form',
  mixins: [FormMixins, UnSaveChangesMixins, TabMixins],
  components: {
    ModalScanQrCode,
    ModalVerifyCode,
    ModalShowSecretCode,
    ModalVerifySuccessfully,
  },
  data() {
    return {
      formData: {
        preferredTwoFaMethod: null,
        defaultNumberOfRecordsOnList: null,
        language: 'English',
        timeZone: null,
        qrCodeUri: '',
        secretCode: '',
        sid: '',
      },
      verifyContent: '',
      preferredTwoFaMethodList: [],
      itemPerPageList: [],
      languageOptionDummy: [{ value: 1, label: 'English' }],
      timeZoneList: [],
      twoFaMethodValid: [],
      preferredTwoFaMethodSelected: null,
      isWrongCode: false,
      hadSetupAuthy: false,
      hadSetupSms: false,
      reportingStatusList: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    myAccountSettingSchema() {
      return getMyAccountSettingsSchema(this.twoFaMethodValid);
    },
    preferredTwoFaMethodChange() {
      return this.formData.preferredTwoFaMethod?.label;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
  },
  watch: {
    preferredTwoFaMethodChange() {
      if (this.preferredTwoFaMethodChange === 'Sms') {
        this.preferredTwoFaMethodSelected = 1;
      } else {
        if (this.preferredTwoFaMethodChange === 'Authy') {
          this.preferredTwoFaMethodSelected = 2;
        } else {
          if (this.preferredTwoFaMethodChange === 'Email') {
            this.preferredTwoFaMethodSelected = 3;
          } else {
            this.preferredTwoFaMethodSelected = null;
          }
        }
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.MFA_METHOD,
        DROPDOWN_SHORT_NAME.ITEM_PER_PAGE,
        DROPDOWN_SHORT_NAME.TIME_ZONE,
        DROPDOWN_SHORT_NAME.REPORTING_STATUS,
      ]);
      this.preferredTwoFaMethodList = dropdownOptions[DROPDOWN_SHORT_NAME.MFA_METHOD];
      this.itemPerPageList = dropdownOptions[DROPDOWN_SHORT_NAME.ITEM_PER_PAGE];
      this.timeZoneList = dropdownOptions[DROPDOWN_SHORT_NAME.TIME_ZONE];
      this.reportingStatusList = dropdownOptions[DROPDOWN_SHORT_NAME.REPORTING_STATUS];
      this.getTwoFaMethodValid();
      let myAccountSettings = await this.getMyAccountSettings();
      this.formData.preferredTwoFaMethod = this.preferredTwoFaMethodList.find(
        (e) => e.value === myAccountSettings.mfaMethodId,
      );
      this.formData.defaultNumberOfRecordsOnList = this.itemPerPageList.find(
        (e) => e.value === myAccountSettings.pagesizeId,
      );
      this.formData.timeZone = this.timeZoneList.find((e) => e.value === myAccountSettings.timezoneId);
      this.formData.reportingStatusId = this.reportingStatusList.find(
        (e) => e.value === myAccountSettings.reportingStatusId,
      );
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
    async getMyAccountSettings() {
      const res = await MyAccountService.getMyAccountSettings(this.userId);
      if (res.err) {
        return this.$alertError('Err: ' + res.error);
      }
      return res.data;
    },
    getTwoFaMethodValid() {
      this.preferredTwoFaMethodList.forEach(async (preferredFaMethod) => {
        let checkMethodIsAvailable = await this.checkTwoFaMethodIsAvailable(preferredFaMethod.value);
        if (checkMethodIsAvailable === true) {
          this.twoFaMethodValid.push(preferredFaMethod.value);

          if (preferredFaMethod.label === 'Authy') {
            this.hadSetupAuthy = true;
          }
          if (preferredFaMethod.label === 'Sms') {
            this.hadSetupSms = true;
          }
        }
      });
    },
    async checkTwoFaMethodIsAvailable(mfaMethodId) {
      const res = await MyAccountService.checkTwoFaMethodIsAvailable(this.userId, mfaMethodId);
      if (res.err) {
        return this.$alertError('Err: ' + res.error);
      }
      return res.data;
    },
    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          if (this.preventUserSavingWithOutSettingUpAccount()) {
            try {
              const res = await MyAccountService.updateMyAccountSettings(
                this.userId,
                this.formData.defaultNumberOfRecordsOnList?.value,
                this.formData.preferredTwoFaMethod?.value,
                this.formData.timeZone?.value,
                this.isPathView ? this.formData.reportingStatusId?.value : null,
              );
              if (res.err || res.error) {
                return this.$alertError(res.error);
              }
              await this.$store.dispatch('config/$UPDATE_ITEM_PER_PAGE_DEFAULT', {
                value: this.formData.defaultNumberOfRecordsOnList?.label,
              });
              await this.$store.dispatch('config/$UPDATE_TIME_ZONE', {
                value: this.formData.timeZone.fieldItemName.substring(4, 10),
              });
              await this.$store.dispatch('config/$UPDATE_REPORTING_STATUS_ID', {
                value: this.formData.reportingStatusId?.value,
              });
              this.$resetChangeDetection();
              this.$alertSuccess(this.$t('entities/my-account/settings/save.success'));
              setTimeout(() => {
                this.$router.push(APP_ROUTES.DASHBOARD);
              }, 1000);
            } catch (error) {
              return this.$alertError('Err: ' + error);
            }
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    preventUserSavingWithOutSettingUpAccount() {
      if (
        this.formData.preferredTwoFaMethod &&
        this.formData.preferredTwoFaMethod.fieldItemName === 'Authy' &&
        !this.hadSetupAuthy
      ) {
        this.$alertError(this.$t('entities/my-account/settings/withOutSettingUpAuthyError'));
        return false;
      }
      if (
        this.formData.preferredTwoFaMethod &&
        this.formData.preferredTwoFaMethod.fieldItemName === 'Sms' &&
        !this.hadSetupSms
      ) {
        this.$alertError(this.$t('entities/my-account/settings/withOutSettingUpSmsError'));
        return false;
      }
      return true;
    },
    onCompleteScanQrCode() {
      this.$refs.scanQrCodeModal.close();
      this.$refs.showSecretCode.close();
      this.verifyContent = 'Authy app';
      this.$refs.verifyCodeModal.open();
    },
    onShowSecretCode() {
      this.$refs.scanQrCodeModal.close();
      this.$refs.showSecretCode.open();
    },
    async onClickSetupTwoFaViaAuthy() {
      const res = await MyAccountService.getSecretCodeToSetupTotp(this.userId);
      if (res.err) {
        return this.$alertError('Error: ' + res.error);
      }
      this.formData.qrCodeUri = res.data.binding.uri;
      this.formData.secretCode = res.data.binding.secret;
      this.formData.sid = res.data.sid;
      this.$refs.scanQrCodeModal.open();
    },
    async onVerifyCode(data) {
      const res = await MyAccountService.verifySetupTotp(this.userId, this.formData.sid, data.code);
      if (res.err) {
        switch (res.error) {
          case VERIFY_SMS_ERROR.ServiceIsUnavailable:
            this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailableAuthy'));
            break;
          case VERIFY_SMS_ERROR.VerifyCodeIncorrect:
            this.isWrongCode = true;
            break;
          default:
            this.$alertError('Error: ' + res.error);
        }
      } else {
        this.$refs.verifyCodeModal.close();
        this.$refs.verifySuccessfullyModal.open();
      }
    },
    onVerifySuccessfully() {
      this.$refs.verifySuccessfullyModal.close();
      this.$router.go();
    },
  },
};
</script>
<style lang="scss" scoped>
.my-account-setting {
  width: 80%;
  margin: 0 auto;
  padding: 0 20px;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
.preferred-method {
  color: red;
  font-size: 0.8rem;
}
.setUp2FABtn {
  height: 36px;
  margin-top: 10px;
}
</style>
