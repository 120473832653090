import { cloneDeep } from 'lodash';

const MyAccountSettingsSchema = {
  information: {
    entity: 'my-account/settings',
    fields: {
      preferredTwoFaMethod: 'required',
      setUpAuthy: '',
      defaultNumberOfRecordsOnList: 'required',
      language: 'required',
      timeZone: 'required',
      reportingStatusId: '',
    },
  },
  _serverSideValidators: {
    preferredTwoFaMethod: {
      fieldName: 'preferredTwoFaMethod',
      errorType: 'unique',
      functionName: 'checkTwoFaMethodAvailable',
      params: {},
    },
  },
};

const prepareMyAccountSettingsSchema = (myAccountSettingsSchema, params) => {
  const schema = cloneDeep(myAccountSettingsSchema);

  schema._serverSideValidators.preferredTwoFaMethod.params = {
    listTwoFaMethodAvailable: params,
  };

  return schema;
};

export const getMyAccountSettingsSchema = (params) => {
  return prepareMyAccountSettingsSchema(MyAccountSettingsSchema, params).information;
};
